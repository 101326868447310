import './CardBooking.css';
import {ReactComponent as ArrowDownIcon}  from '../../../../assets/icons/arrow_down_view.svg';
import {ReactComponent as ShipIcon}  from '../../../../assets/icons/ship_days.svg';
import {ReactComponent as MenaIcon}  from '../../../../assets/icons/mena_mersat.svg';

import TimeLine from '../TimeLine/TimeLine';
import CardDetails from './CardDetails';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addBookingObject,setShouldClearForm ,setGlobalOceanFreight} from '../../store/home/home.slice';
import { useDispatch,useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


const CardBooking = ({item,portsObject,image,index,setCardsPrice}) => {

	const {
		globalOceanFreight,
	} = useSelector((state) => state.moduleMain.homeSlice);

	const [detailsVisible, setDetailsVisible] = useState({});
  const [calculatedOceanFreight, setCalculatedOceanFreight] = useState(item.ocean_freight); // Default to original ocean_freight
  let newOceanFreight = calculatedOceanFreight;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t , i18n} = useTranslation();
  const {
	loadingForm,
	// postAppointmentForm,
	// errorAppointmentForm

} = useSelector((state) => state.moduleMain.homeSlice);
let ocean_freight2=item.ocean_freight;

const toggleDetails = (index) => {
	setDetailsVisible((prevDetails) => {
		return {
			...prevDetails,
			[index]: !prevDetails[index], // Toggle visibility for the clicked button
		};
	});
};

const toggleChecked = (index, valueChecked, field) => {
	setCardsPrice((prevCards) => {
		const updatedCards = [...prevCards];

		// Create a new variable for ocean freight
		// let newOceanFreight = calculatedOceanFreight;

		// Assuming you want to toggle the 'checked' property
		if (field === 'checkedOrigin') {
			if (!valueChecked) {
				newOceanFreight += Number(updatedCards[index].port_of_origin);
			} else {
				newOceanFreight -= Number(updatedCards[index].port_of_origin);
			}
			updatedCards[index] = { ...updatedCards[index], checkedOrigin: !valueChecked };
		}

		if (field === 'checkedDistanation') {
			if (!valueChecked) {
				newOceanFreight += Number(updatedCards[index].port_of_discharge);
			} else {
				newOceanFreight -= Number(updatedCards[index].port_of_discharge);
			}
			updatedCards[index] = { ...updatedCards[index], checkedDistanation: !valueChecked };
		}

		// Update the calculated ocean freight state variable
		setCalculatedOceanFreight(newOceanFreight);

		// dispatch(setGlobalOceanFreight(newOceanFreight));

		return updatedCards;
	});
};


  const handleBooking = (item)=>{

	 setCalculatedOceanFreight(newOceanFreight);
	dispatch(setGlobalOceanFreight(newOceanFreight));
		dispatch(addBookingObject(item));
    dispatch(setShouldClearForm(true));
		  navigate('add-book')
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});


  }


  return (
    <div className='card-book_item'>
{/* {console.log('above-button',item.pickup)}
{console.log('ميناء التفريغ',item.port_of_discharge)}
{console.log('ميناء التحميل',item.port_of_origin)}
{console.log('الاساسي',item.ocean_freight)}
{console.log('الاساسي',item)} */}


      <div className='row_1'>

      <div className='item_img'>
        {image}
      </div>

      <section className='item_timeline' style={{direction: i18n.language==="ar"? 'ltr' : 'rtl'}}>

        <TimeLine startDate={item.date} endDate={item.end_date} portTitleFrom={t('bookingTitles.placeholderPortTo')} portTitleTo={t('bookingTitles.placeholderPortFrom')} cityFrom={portsObject?.portTo} cityTo={portsObject?.portFrom}  numberStations={item.number_of_station} numberDays={item.number_of_day}/>

      </section>

      <div className='item_booking'>
			<span className='btn_value' style={{display:'flex',justifyContent: 'center',color: '#0D3453', fontWeight:'bold', fontSize:'1.8em',margin:'-33px 0px 4px 0px',columnGap:'1.5px',fontFamily:' var(--font-family-ar-primary)'}}>
          <div fontSize style={{fontFamily:'var(--font-family-ar-primary)',alignSelf:'center',fontSize:'0.7rem'}}>USD</div> {calculatedOceanFreight}




        </span>
        <button   disabled={loadingForm} className='booking_btn' onClick={()=>handleBooking(item)} style={{background:  '#fcc400' }}>

        <span className="button-hover">
          {t('actions.buttonBook')}
		              <span className={`${loadingForm && 'btn-ring'}`}></span>

        </span>

        </button>

        <div className='booking-details' onClick={()=>toggleDetails(index)}>

          <ArrowDownIcon className={`details-icon ${detailsVisible[index] ? 'rotated':''} `}/>
          <span className='details-title'>
          {t('actions.showdetails')}
          </span>

        </div>
				</div>
      </div>

      {detailsVisible[index] &&
      <div className='row_2'>

    {item.port_of_origin !== 0 && (
      <CardDetails
        isStyleIcon={"details_info-icon2"}
        Icon={<MenaIcon />}
        field={"checkedOrigin"}
        toggleChecked={toggleChecked}
        infoTitle={t('bookingTitles.labelPortOrigin')}
        priceOrigin={item.port_of_origin}
        index={index}
        checkedValue={item.checkedOrigin}
      />
    )}
		<CardDetails  Icon={<ShipIcon/>} infoTitle={t('bookingTitles.labelOriginFright')} priceOcean={item.ocean_freight} index={index} checkedValue={true}  />

		{item.port_of_discharge !== 0 &&(
        <CardDetails isStyleIcon={"details_info-icon2"} Icon={<MenaIcon/>} field={"checkedDistanation"} toggleChecked={toggleChecked}  infoTitle={t('bookingTitles.labelPortDistanition')} priceDistantion={item.port_of_discharge} index={index} checkedValue={item.checkedDistanation}

		/>
    )}
		  </div>
      }

    </div>
  )
}

export default CardBooking
